<template>
  <b-overlay
    :show="showLoading"
    rounded="sm"
    variant="white"
    opacity="0.50"
  >
    <b-card-actions
      ref="allPropertiesListActionCard"
      :title="$t('menu.properties.children.all_properties')"
      action-refresh
      action-collapse
      @refresh="loadPropertiesFunction"
    >

      <b-row>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">العدد</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="my-1"
        >
          <b-form-group
            label="بحث"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        class="position-relative"
        :items="propertiesList"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        show-empty
        striped
        hover
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        empty-text="No matching records found"
      >
        <!-- Column: Actions -->
        <template #cell(feature_image_url)="data">
          <b-avatar
            size="lg"
            :src="data.item.feature_image_url"
          />
        </template>

        <template #cell(bankable)="data">
          <b-avatar
            size="sm"
            :variant="data.item.bankable ? 'success' : 'danger'"
          >
            <feather-icon :icon="data.item.bankable ? 'CheckIcon' : 'XIcon'" />
          </b-avatar>
        </template>

        <template #cell(initiative_bankable)="data">
          <b-avatar
            size="sm"
            :variant="data.item.initiative_bankable ? 'success' : 'danger'"
          >
            <feather-icon :icon="data.item.initiative_bankable ? 'CheckIcon' : 'XIcon'" />
          </b-avatar>
        </template>

        <template #cell(price)="data">
          <span style="font-weight: bold; font-size: 15px">{{ data.item.price }}</span>
        </template>

        <template #cell(approved)="data">
          <b-avatar
            size="sm"
            :variant="data.item.approved ? 'success' : 'danger'"
          >
            <feather-icon :icon="data.item.approved ? 'CheckIcon' : 'XIcon'" />
          </b-avatar>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              @click="deletePropertyFunction(data.item.id)"
            >
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="propertiesList.length"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card-actions>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line import/extensions
import BCardActions from '@core/components/b-card-actions/BCardActions'
import {
  BTable, BAvatar, BCol, BRow, BFormGroup, BInputGroup, BFormInput,
  BInputGroupAppend, BButton, BFormSelect, BOverlay, BPagination, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'

export default {
  name: 'AllProperties',
  components: {
    BTable,
    BAvatar,
    BCol,
    BRow,
    BPagination,
    BCardActions,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormSelect,
    BOverlay,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      modalShow: false,
      perPage: 5,
      pageOptions: [1, 5, 10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      avatarText,
      filter: null,
      filterOn: [],
      fields: [
        { key: 'feature_image_url', label: 'الصورة الرئيسية' },
        { key: 'name', label: 'الإسم', sortable: true },
        { key: 'bedrooms', label: 'عدد الغرف', sortable: true },
        { key: 'bathrooms', label: 'عدد الحمامات', sortable: true },
        { key: 'space_area', label: 'المساحة م2', sortable: true },
        { key: 'price', label: 'السعر', sortable: true },
        { key: 'city_name', label: 'المدينة', sortable: true },
        { key: 'bankable', label: 'قابلة للتمويل', sortable: true },
        { key: 'initiative_bankable', label: 'قابلة لتمويل المبادرة', sortable: true },
        { key: 'type', label: 'النوع', sortable: true },
        { key: 'status', label: 'الحالة', sortable: true },
        { key: 'approved', label: 'متاحة', sortable: true },
        { key: 'actions' },
      ],
      showLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      propertiesList: 'properties/propertiesList',
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  async created() {
    this.showLoading = true
    await this.loadPropertiesFunction()
    this.totalRows = this.propertiesList.length
    this.showLoading = false
  },
  methods: {
    ...mapActions({
      get: 'get',
      delete: 'delete',
    }),
    async loadPropertiesFunction() {
      this.showLoading = true
      await this.get({
        storeName: 'properties',
        url: '/admin/properties',
        commitUrl: 'properties/SET_PROPERTIES',
      })
      this.showLoading = false
      this.$refs.allPropertiesListActionCard.showLoading = false
    },
    async deletePropertyFunction(propertyID) {
      this.showLoading = true
      await this.delete({
        url: `admin/properties/${propertyID}`,
        storeName: 'properties',
      })
      this.showLoading = false
    },
    /* cellClass(item, type) {
      // item.toggleDetails()
      console.log(item)
      console.log(type)
    }, */
  },
}
</script>

<style scoped>

</style>
